import React from "react";
import { useLanguage } from "../context/LanguageContext";
import { translations } from "../utils/translations";

const Header: React.FC = () => {
  const { language, toggleLanguage } = useLanguage();
  const t = translations[language]; // Pega as traduções de acordo com o idioma selecionado
  return (
<header style={{ backgroundColor: "#4050B5", padding: "20px", color: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  <img
    src="/logo/smart-crafters.png" // Substitua pelo caminho da sua imagem
    alt="Logo da Empresa"
    style={{ height: "100px" }}
  />
  
  {/* <button
    onClick={toggleLanguage}
    style={{
      backgroundColor: "transparent",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      fontSize: "1rem",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {language === "pt" ? (
      <img
        src="/ico/pt-br.svg" // Substitua pelo caminho da sua imagem
        alt="Ícone de idioma português"
        style={{ height: "60px" }}
      />
    ) : (
      <img
        src="/ico/united-states.svg" // Substitua pelo caminho da sua imagem
        alt="Ícone de idioma inglês"
        style={{ height: "60px" }}
      />
    )}
  </button> */}
</header>
  );
};

export default Header;
