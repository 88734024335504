import React from "react";
import { Link } from "react-router-dom";

const products = [
  { id: 1, title: "NoteFlex", description: "Seu Bloco de Notas Simples e Poderoso" },
 // { id: 2, title: "NoteList", description: "Aplicativos de lista de compras" },

];

const Products: React.FC = () => {
  return (
    <section style={{ padding: "20px" }}>
      <h2 style={{ textAlign: "center" }}>Nossos Produtos</h2>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap" }}>
      <Link to="/noteflex" style={{ color: "#4e4e4e" }}>
        {products.map((products) => (
          <div
            key={products.id}
            style={{
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "10px",
              maxWidth: "300px",
              textAlign: "center",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h3>{products.title}</h3>
            <p>{products.description}</p>
          </div>
        ))}
        </Link>
      </div>
    </section>
  );
};

export default Products;
