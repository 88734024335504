// src/pages/PrivacyPolicy.tsx

import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";
import { translations } from "../utils/translations";

const UsePrivacyPolicy: React.FC = () => {
  const { language, toggleLanguage } = useLanguage();
  const t = translations[language]; // Pega as traduções de acordo com o idioma selecionado

  return (
    <div>
      <header style={{ backgroundColor: "#4050B5", padding: "20px", color: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <img
          src="/logo/smart-crafters.png" // Substitua pelo caminho da sua imagem
          alt="Logo da Empresa"
          style={{ height: "100px" }}
        />

        <button
          onClick={toggleLanguage}
          style={{
            backgroundColor: "transparent",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontSize: "1rem",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {language === "pt" ? (
            <img
              src="/ico/pt-br.svg" // Substitua pelo caminho da sua imagem
              alt="Ícone de idioma português"
              style={{ height: "60px" }}
            />
          ) : (
            <img
              src="/ico/united-states.svg" // Substitua pelo caminho da sua imagem
              alt="Ícone de idioma inglês"
              style={{ height: "60px" }}
            />
          )}
        </button>
      </header>
      <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>

        <h1>{t.title}</h1>
        <p>{t.lastUpdate}</p>

        <h2>{t.introductionTitle}</h2>
        <p>
          {t.introduction}
        </p>

        <h2>{t.informationCollectTitle}</h2>
        <p>
          {t.informationCollect}
        </p>

        <h2>{t.howUseInformationTitle}</h2>
        <p>
          {t.howUseInformation}
        </p>

        <h2>{t.userResponsibilityTitle}</h2>
        <p>
          {t.userResponsibility}
        </p>

        <h2>{t.informationSecurityTitle}</h2>
        <p>
          {t.informationSecurity}
        </p>

        <h2>{t.informationSharingTitle}</h2>
        <p>
          {t.informationSharing}
        </p>

        <h2>{t.dataRetentionTitle}</h2>
        <p>
          {t.dataRetention}
        </p>

        <h2>{t.changesPolicyTitle}</h2>
        <p>
          {t.changesPolicy}
        </p>

        <h2>{t.contactTitle}</h2>
        <p>
          {t.contact}
          <strong>smartcraftershelp@gmail.com</strong>
        </p>



        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
          <Link to="/" style={{
            marginLeft: "0px",
            backgroundColor: "#292929",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontSize: "1rem",
            cursor: "pointer",
          }}>
            {t.backButton}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UsePrivacyPolicy;