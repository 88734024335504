// src/utils/translations.ts

export const translations = {
    pt: {
      title: "Política de Privacidade e Uso",
      lastUpdate: "Última atualização: Dezembro de 2024",
      
      introductionTitle: "Introdução",
      introduction: `Esta Política de Privacidade descreve como lidamos com as informações dos usuários do aplicativo
        NoteFlex, que armazena suas anotações na memória interna do dispositivo. Nós não coletamos, armazenamos
        ou compartilhamos informações pessoais de forma alguma.`,
      
      informationCollectTitle: "Informações que Coletamos",
      informationCollect: `O aplicativo NoteFlex não coleta nenhuma informação pessoal dos usuários. Todas as anotações
      feitas dentro do aplicativo são armazenadas localmente no dispositivo, sem a necessidade de conexão com a
      internet ou de transferência de dados para servidores externos.`,

      howUseInformationTitle: "Como Usamos as Informações",
      howUseInformation: `Não utilizamos suas anotações para nenhum outro propósito além de permitir que você armazene e acesse
        suas notas de maneira prática e segura no dispositivo.`,

      userResponsibilityTitle: "Responsabilidade do Usuário",
      userResponsibility: `Como as anotações são armazenadas exclusivamente na memória interna do seu dispositivo, é de total
        responsabilidade do usuário gerenciar, acessar e fazer backup dos dados. Recomendamos que você faça backups
        regulares de suas anotações para evitar a perda de dados em caso de falhas no dispositivo.`,

      informationSecurityTitle: "Segurança das Informações",
      informationSecurity: `Embora o aplicativo armazene suas anotações localmente, tomamos cuidados para garantir que elas fiquem
        acessíveis apenas ao usuário. No entanto, a segurança dos dados depende do dispositivo e das configurações
        de segurança do mesmo (como senhas e criptografia de disco).`,

      informationSharingTitle: "Compartilhamento de Informações",
      informationSharing: `O aplicativo não compartilha suas anotações ou qualquer outro dado com terceiros. As informações são
        armazenadas exclusivamente no seu dispositivo e não são enviadas para nenhum servidor.`,

      dataRetentionTitle: "Retenção de Dados",
      dataRetention: `As anotações serão mantidas enquanto você as mantiver no dispositivo. Caso deseje excluir ou limpar
        suas anotações, você pode fazê-lo a qualquer momento, diretamente no aplicativo.`,

      changesPolicyTitle: "Alterações a Esta Política",
      changesPolicy: `Podemos atualizar nossa Política de Privacidade periodicamente. Notificaremos sobre quaisquer
        alterações publicando a versão atualizada dentro do próprio aplicativo. Recomendamos que você revise esta
        página de tempos em tempos para estar ciente de qualquer alteração.`,

      contactTitle: "Contato",
      contact: "Se você tiver alguma dúvida sobre nossa Política de Privacidade, entre em contato conosco pelo e-mail:",

      backButton: "Voltar para o SmartCrafters",

      privacyPolicy: "Política de Privacidade e Uso"
    },
    en: {
      title: "Use and Privacy Policy",
      lastUpdate: "Last updated: December 2024",

      introductionTitle: "Introduction",
      introduction: `This Privacy Policy describes how we handle application user information
        of NoteFlex, which stores your notes in the device's internal memory. We do not collect, store
        or share personal information in any way.`,
      
      informationCollectTitle: "Information We Collect",
      informationCollect: `The NoteFlex application does not collect any personal information from users. All notes
      made within the application are stored locally on the device, without the need to connect to the
      internet or data transfer to external servers.`,

      howUseInformationTitle: "How We Use Information",
      howUseInformation: `We do not use your notes for any purpose other than to allow you to store and access
        your notes in a practical and secure way on your device.`,

      userResponsibilityTitle: "User Responsibility",
      userResponsibility: `Since notes are stored exclusively on your device's internal memory, it is completely
        It is the user's responsibility to manage, access and back up data. We recommend that you make backups
        check your notes regularly to avoid data loss in case of device failures.`,

      informationSecurityTitle: "Information Security",
      informationSecurity: `Although the app stores your notes locally, we take care to ensure they stay
        accessible only to the user. However, data security depends on the device and settings
        security features (such as passwords and disk encryption).`,

      informationSharingTitle: "Information Sharing",
      informationSharing: `The app does not share your notes or any other data with third parties. The information is
        stored exclusively on your device and are not sent to any server.`,

      dataRetentionTitle: "Data Retention",
      dataRetention: `Notes will be retained as long as you keep them on your device. If you want to delete or clear
        your notes, you can do it at any time, directly in the app.`,

      changesPolicyTitle: "Changes to This Policy",
      changesPolicy: `We may update our Privacy Policy periodically. We will notify you of any
        changes by publishing the updated version within the application itself. We recommend that you review this
        page from time to time to be aware of any changes.`,

      contactTitle: "Contact",
      contact: "If you have any questions about our Privacy Policy, please contact us by email:",

      backButton: "Back to SmartCrafters",

      privacyPolicy: "Use and Privacy Policy"
    }
  };
  