import React from "react";

const Introduction: React.FC = () => {
  return (
    <section style={{ padding: "20px", textAlign: "center" }}>
      <h2>Bem-vindo à SmartCrafters!</h2>


      <p className="texto-introducao">
        Nossa paixão é criar soluções digitais que fazem a diferença. Somos uma empresa dedicada ao desenvolvimento de aplicativos inovadores e funcionais, projetados para atender às necessidades do dia a dia com praticidade e eficiência.
      </p>
      <p className="texto-introducao">
        Aqui, você encontrará um portfólio cuidadosamente desenvolvido, onde cada aplicativo reflete nossa dedicação à qualidade, à usabilidade e à tecnologia de ponta. Não criamos apenas ferramentas digitais; criamos experiências que simplificam a vida.
      </p>
      <p className="texto-introducao">
        Explore nossos aplicativos e descubra como a <strong>SmartCrafters</strong> está moldando o futuro da tecnologia com criatividade e propósito. Bem-vindo à inovação!
      </p>

    </section>
  );
};

export default Introduction;
