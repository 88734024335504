import React from "react";

const Footer: React.FC = () => {
  return (
    <footer style={{ backgroundColor: "#f1f1f1", padding: "10px", textAlign: "center", marginTop: "150px" }}>
      <p>&copy; 2025 SmartCrafters. Todos os direitos reservados.</p>
      <p>Email: smartcraftershelp@gmail.com</p>
    </footer>
  );
};

export default Footer;
