// src/pages/PrivacyPolicy.tsx

import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";
import { translations } from "../utils/translations";

const NoteFlex: React.FC = () => {
  const { language, toggleLanguage } = useLanguage();
  const t = translations[language]; // Pega as traduções de acordo com o idioma selecionado

  const noteFlexButtonClick = () => {
    window.location.href = "https://www.google.com";
  };

  return (
    <div>
<header style={{ backgroundColor: "#4050B5", padding: "20px", color: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  <img
    src="/logo/smart-crafters.png" // Substitua pelo caminho da sua imagem
    alt="Logo da Empresa"
    style={{ height: "100px" }}
  />
  
  {/* <button
    onClick={toggleLanguage}
    style={{
      backgroundColor: "transparent",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      fontSize: "1rem",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {language === "pt" ? (
      <img
        src="/ico/pt-br.svg" // Substitua pelo caminho da sua imagem
        alt="Ícone de idioma português"
        style={{ height: "60px" }}
      />
    ) : (
      <img
        src="/ico/united-states.svg" // Substitua pelo caminho da sua imagem
        alt="Ícone de idioma inglês"
        style={{ height: "60px" }}
      />
    )}
  </button> */}
</header>
    

    <div style={{ padding: "20px", maxWidth: "820px", margin: "auto", fontFamily: "Arial, sans-serif" }}>


      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
        NoteFlex – Seu Bloco de Notas Simples e Poderoso
      </h1>
      <p style={{ fontSize: "1.0rem", lineHeight: "1.8", marginBottom: "20px" }}>
        Organize suas ideias, tarefas e anotações de forma prática e eficiente com o <strong>NoteFlex</strong>!
        Projetado para ser intuitivo e rápido, o NoteFlex oferece tudo o que você precisa em um aplicativo de bloco
        de notas, sem distrações desnecessárias.
      </p>
      <h2 style={{ marginBottom: "15px" }}>Principais recursos:</h2>
      <ul style={{ fontSize: "1rem", lineHeight: "1.8", paddingLeft: "20px", marginBottom: "20px" }}>
        <li>🌟 <strong>Interface Limpa</strong>: Foco total nas suas anotações, com um design simples e funcional.</li>
        <li>📝 <strong>Organização Personalizada</strong>: Categorize suas notas para encontrá-las rapidamente.</li>
        <li>🔒 <strong>Privacidade Garantida</strong>: Suas anotações são armazenadas localmente no dispositivo, sem coleta de dados.</li>
        <li>📤 <strong>Backup Manual</strong>: Salve e restaure suas notas quando precisar, mantendo o controle total dos seus dados.</li>
      </ul>

      <img
        src="noteflex\home.png" // Substitua pelo caminho do logo do NoteFlex
        alt="NoteFlex"
        style={{ height: "500px" }}
      />

      <img
        src="noteflex\note-list.png" // Substitua pelo caminho do logo do NoteFlex
        alt="NoteFlex"
        style={{ marginLeft: "70px", height: "500px" }}
      />
      <img
        src="noteflex\lista.png" // Substitua pelo caminho do logo do NoteFlex
        alt="NoteFlex"
        style={{ marginLeft: "70px", height: "500px" }}
      />

      <p style={{ fontSize: "1.0rem", lineHeight: "1.8", marginBottom: "20px" }}>
        Seja para planejar seu dia, registrar ideias ou criar listas de tarefas, o <strong>NoteFlex</strong> é a solução perfeita
        para suas necessidades diárias. Baixe agora e tenha um bloco de notas sempre à mão!
      </p>


      <div style={{ textAlign: "left", marginTop: "70px" }}>
        <Link to="use-privacy-policy"
          style={{
            backgroundColor: "#007BFF",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontSize: "1rem",
            cursor: "pointer",
          }}
        >
          {t.privacyPolicy}
        </Link>
        {/* <button
          onClick={noteFlexButtonClick}
          style={{
            marginLeft: "490px",
            backgroundColor: "#007BFF",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontSize: "1rem",
            cursor: "pointer",
          }}
        >
          Baixe Agora
        </button> */}
      </div>


      <div style={{ marginTop: "50px", marginBottom:"50px", }}>
        <Link to="/" style={{
          marginLeft: "0px",
          backgroundColor: "#292929",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          fontSize: "1rem",
          cursor: "pointer",
        }}>
          {t.backButton}
        </Link>
      </div>
      
    </div>
    <footer style={{ backgroundColor: "#f1f1f1", padding: "10px", textAlign: "center", marginTop: "150px" }}>
      <p>&copy; 2025 SmartCrafters. Todos os direitos reservados.</p>
      <p>Email: smartcraftershelp@gmail.com</p>
    </footer>
    </div>
    
  );
};

export default NoteFlex;