// src/App.tsx

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import UsePrivacyPolicy from './pages/UsePrivacyPolicy';
import Header from "./components/Header";
import Introduction from "./components/Introduction";
import Products from "./components/Products";
import Footer from "./components/Footer";
import NoteFlex from './pages/NoteFlex';

const App: React.FC = () => {
  return (
    <LanguageProvider>
      <Router>
      <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
          <Routes>
            <Route path="/"
            element={<><Header /><Introduction /><Products /><Footer /></>}
            />
            <Route path="noteflex" element={<NoteFlex />} />
            <Route path="noteflex/use-privacy-policy" element={<UsePrivacyPolicy />} />
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;